'use client'

import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cva } from 'class-variance-authority'
import * as React from 'react'
import { Badge, IColorBadgeType } from '~/core/ui/Badge'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'

const switchTabVariants = cva(
  'flex items-center rounded justify-between bg-gray-100 dark:bg-gray-800',
  {
    variants: {
      size: {
        xs: 'p-px',
        sm: 'p-0.5',
        md: 'p-0.5',
        lg: 'p-0.5'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

const spaceTabInPillVariants = cva('', {
  variants: {
    size: {
      xs: 'space-x-4',
      sm: 'space-x-4',
      md: 'space-x-6',
      lg: 'space-x-6'
    },
    width: {
      default: '',
      'full-width': 'flex items-center w-full space-x-0'
    }
  },
  defaultVariants: {
    size: 'md',
    width: 'default'
  }
})

const spaceTabWithUnderlineVariants = cva('', {
  variants: {
    size: {
      xs: 'space-x-6',
      sm: 'space-x-6',
      md: 'space-x-8',
      lg: 'space-x-8'
    },
    width: {
      default: '',
      'full-width': 'flex items-center w-full space-x-0'
    }
  },
  defaultVariants: {
    size: 'md',
    width: 'default'
  }
})

const triggerTabWithUnderline =
  'px-1 border-b-2 border-solid group text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 border-b-transparent hover:border-b-gray-300 dark:hover:border-b-gray-600 data-[state=active]:text-gray-900 dark:data-[state=active]:text-gray-200 data-[state=active]:border-b-gray-600 dark:data-[state=active]:border-b-gray-300'

const triggerSwitchTabVariants = cva(
  'rounded group text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 data-[state=active]:text-gray-900 data-[state=active]:bg-white data-[state=active]:shadow dark:data-[state=active]:text-gray-300 dark:data-[state=active]:bg-gray-700',
  {
    variants: {
      size: {
        xs: 'px-2 py-0.5',
        sm: 'px-3 py-1',
        md: 'px-4 py-[7px]',
        lg: 'px-4 py-[7px]'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

const triggerTabInPillsVariants = cva(
  'rounded group text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 data-[state=active]:text-gray-900 dark:data-[state=active]:text-gray-200 data-[state=active]:bg-gray-100 dark:data-[state=active]:bg-gray-700',
  {
    variants: {
      size: {
        xs: 'px-4 py-[9px]',
        sm: 'px-3 py-1.5',
        md: 'px-4 py-[9px]',
        lg: 'px-4 py-[9px]'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

const fontVariants = cva('font-medium', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-base'
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

type TabSizeProps = 'sm' | 'md' | 'xs' | 'lg'
type TabGapSizeProps = 'sm' | 'md'
type TabTypeProps = 'tab-with-underline' | 'tabs-in-pills' | 'switch-tab'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & {
    size?: TabSizeProps
    typeTab?: TabTypeProps
    isFullWidth?: boolean
  }
>(
  (
    {
      className,
      size = 'md',
      typeTab = 'tab-with-underline',
      isFullWidth = false,
      ...props
    },
    ref
  ) => {
    if (typeTab === 'tabs-in-pills') {
      return (
        <TabsPrimitive.List
          ref={ref}
          className={cn(
            'flex',
            spaceTabInPillVariants({
              size,
              width: isFullWidth ? 'full-width' : 'default'
            })
          )}
          {...props}
        />
      )
    }

    if (typeTab === 'switch-tab') {
      return (
        <div className={cn(switchTabVariants({ size }))}>
          <TabsPrimitive.List
            className={`flex ${isFullWidth ? 'w-full' : ''}`}
            ref={ref}
            {...props}
          />
        </div>
      )
    }

    return (
      <TabsPrimitive.List
        ref={ref}
        className={cn(
          'flex',
          spaceTabWithUnderlineVariants({
            size,
            width: isFullWidth ? 'full-width' : 'default'
          })
        )}
        {...props}
      />
    )
  }
)
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    size?: TabSizeProps
    gapSize?: TabGapSizeProps
    typeTab?: TabTypeProps
    isFullWidth?: boolean
    classNameButton?: string
  }
>(
  (
    {
      className,
      classNameButton,
      size = 'md',
      gapSize = 'md',
      isFullWidth = false,
      typeTab = 'tab-with-underline',
      ...props
    },
    ref
  ) => {
    if (typeTab === 'tabs-in-pills') {
      return (
        <TabsPrimitive.Trigger
          ref={ref}
          className={cn(
            triggerTabInPillsVariants({
              size
            }),
            isFullWidth ? 'flex flex-1 items-center justify-center' : ''
          )}
          {...props}
        />
      )
    }

    if (typeTab === 'switch-tab') {
      return (
        <TabsPrimitive.Trigger
          ref={ref}
          className={cn(
            triggerSwitchTabVariants({
              size
            }),
            isFullWidth ? 'flex flex-1 items-center justify-center' : ''
          )}
          {...props}
        />
      )
    }

    return (
      <TabsPrimitive.Trigger
        ref={ref}
        className={cn(
          triggerTabWithUnderline,
          isFullWidth ? 'flex flex-1 items-center justify-center' : '',
          gapSize === 'md' ? 'pb-3.5' : 'pb-2.5',
          classNameButton
        )}
        {...props}
      />
    )
  }
)
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.Content
      ref={ref}
      className={`focus-visible:outline-none ${className || 'mt-3'}`}
      {...props}
    />
  )
})
TabsContent.displayName = TabsPrimitive.Content.displayName

interface TabSourceProps {
  icon?: LucideIconName
  value: string
  label?: string
  count?: number
}

const TabsTriggerView = (props: {
  session?: TabSourceProps
  size?: TabSizeProps
  typeTab?: TabTypeProps
}) => {
  const {
    session = { value: 'item-1' },
    size = 'md',
    typeTab = 'tab-with-underline'
  } = props

  const colorBadgesTab: { [key: string]: IColorBadgeType } = {
    'tab-with-underline': 'gray',
    'tabs-in-pills': 'white',
    'switch-tab': 'gray'
  }

  return (
    <div className="flex items-center">
      {session.icon ? (
        <IconWrapper name={session.icon} size={10} className="" />
      ) : null}

      <span className={cn(fontVariants({ size }))}>{session.label}</span>

      {session.count !== undefined ? (
        <span className="ml-2 hidden group-data-[state=active]:block">
          <Badge
            radius="circular"
            size={size === 'md' ? 'md' : 'sm'}
            color={colorBadgesTab[typeTab]}
          >
            {session.count}
          </Badge>
        </span>
      ) : null}
    </div>
  )
}
TabsTriggerView.displayName = 'TabsTriggerView'

export { Tabs, TabsList, TabsTrigger, TabsContent, TabsTriggerView }
export type { TabSizeProps, TabTypeProps, TabGapSizeProps, TabSourceProps }
